<template>
  <v-form>
    <v-row>
      <v-col>
        <v-text-field
          v-model="category"
          :label="$t('name')"
          :error-messages="errors"
        />
      </v-col>
    </v-row>

    <v-row class="justify-end pa-2">
      <v-btn
        class="grey mr-2"
        :loading="requesting"
        @click="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        class="primary"
        :loading="requesting"
        @click="validate"
      >
        {{ $t('create') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import CategoriesRepository from '@/api/CategoriesRepository'
export default {
  name: 'CreateCategoryForm',
  data () {
    return {
      category: '',
      requesting: false,
      errors: [],
    }
  },

  methods: {
    validate () {
      if (!this.category) {
        this.errors = 'Field is required'
      } else {
        this.errors = []
        this.create()
      }
    },
    create () {
      this.requesting = true
      CategoriesRepository.store({ name: this.category })
        .then(({ data }) => {
          this.$store.commit('snackBar/setSnack', {
            color: 'primary',
            message: 'Category Created',
          })
          this.$emit('categoryCreated', data)
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const data = response.data.errors
            if (data.name) {
              this.errors.push(data.name[0])
            }
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
