import Repository from './Repository'

const resource = '/api/parts'

export default {
  index (params) {
    return Repository.get(`${resource}`, { params })
  },
  show (id, params) {
    return Repository.get(`${resource}/${id}`, { params })
  },
  store (body) {
    return Repository.post(`${resource}/`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  update (partId, body) {
    return Repository.put(`${resource}/${partId}`, body)
  },
  replaceImage (partId, body) {
    return Repository.post(`${resource}/${partId}/replace-image`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
