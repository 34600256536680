import Repository from './Repository'

const resource = '/api/categories'

export default {
  index () {
    return Repository.get(`${resource}`)
  },
  store (body) {
    return Repository.post(`${resource}`, body)
  },
}
