import Repository from './Repository'

function buildUrl (entityId, partId = null) {
  let url = `api/entities/${entityId}/parts`
  if (partId) {
    url = `/${url}/${partId}`
  }

  return url
}

export default {
  index (entityId, params) {
    return Repository.get(buildUrl(entityId), { params })
  },
  store (entityId, body) {
    return Repository.post(buildUrl(entityId), body)
  },
  update (entityId, partId, body) {
    return Repository.put(buildUrl(entityId, partId), body)
  },
}
