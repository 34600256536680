<template>
  <v-container>
    <base-material-card
      icon="mdi-nut"
      :title="$t('c.cartTitle')"
      class="px-5 py-3"
    >
      <v-card-title>{{ $t('c.cartTitle') }}</v-card-title>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-form
          @submit.prevent="submit"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Name"
                rules="required"
              >
                <v-text-field
                  v-model="name"
                  class="purple-input"
                  :label="$t('name') | capitalize"
                  required
                  :error-messages="[...errors]"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Model"
                rules="required"
              >
                <v-text-field
                  v-model="model"
                  class="purple-input"
                  :label="$t('model') | capitalize"
                  required
                  :error-messages="[
                    ...errors,
                    ...serverErrors.model ? serverErrors.model : [] ]"
                />
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="description"
                class="purple-input"
                auto-grow
                rows="1"
                persistent-hint
                :hint="$t('c.description.hint')"
                :label="$t('description') | capitalize"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  v-model="categoryId"
                  :items="categories"
                  :label="$t('category') | capitalize"
                  item-text="description"
                  item-value="value"
                  :error-messages="errors"
                >
                  <template v-slot:prepend-item>
                    <div
                      class="pa-1 ma-1 "
                      style="cursor: pointer"
                      @click="showNewCategoryDialog = true"
                    >
                      <span>
                        <v-icon> mdi-plus</v-icon>
                        <span> {{ $t('c.newCategory') | capitalize }} </span>
                      </span>
                    </div>

                    <v-divider />
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-col>
            <v-file-input
              v-model="file"
              accept="image/*"
              show-size
              :label="$t('image')"
              @change="onFileChange"
            />
          </v-col>
          <v-card-title>{{ $t('c.quantitiesTitle') }}</v-card-title>

          <v-btn
            class="grey mb-8"

            @click="partEntities.push({
              id: '',
              quantity: 0
            })"
          >
            {{ $t('c.addEntity') }}
          </v-btn>
          <v-row
            v-for="partEntity in partEntities"
            :key="partEntity.name"
            class="grey lighten-4 mb-1"
          >
            <v-col
              class="py-0"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Entity "
                rules="required"
              >
                <!-- TODO add validation to select entity only once -->
                <v-select
                  v-model="partEntity.id"
                  class="text--darken-2"
                  :label="$t('name')"
                  :items="entities"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="6"
            >
              <div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="min_value:1"
                  name="Quantity"
                >
                  <v-text-field
                    v-model.number="partEntity.quantity"
                    :label="$t('quantity')"
                    type="number"
                    :error-messages="errors"
                  />
                </validation-provider>
              </div>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col class="d-flex justify-end">
              <v-btn
                class="primary"
                type="submit"
                :loading="requesting"
                :disabled="invalid"
              >
                {{ $t('create') | capitalize }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </base-material-card>
    <v-dialog
      v-if="showNewCategoryDialog"
      v-model="showNewCategoryDialog"
      width="500px"
    >
      <v-card>
        <v-card-title
          class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
        >
          {{ $t('c.newCategory') | capitalize }}
        </v-card-title>
        <create-category-form
          class="pa-4"
          @cancel="showNewCategoryDialog=false"
          @categoryCreated="handleCategoryCreated"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EntitiesRepository from '@/api/EntitiesRepository'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
// eslint-disable-next-line camelcase
import { min_value } from 'vee-validate/dist/rules'
import PartsRepository from '@/api/PartsRepository'
import CategoriesRepository from '@/api/CategoriesRepository'
import EntityPartsRepository from '@/api/EntityPartsRepository'
import CreateCategoryForm from '@/views/dashboard/components/CreateCategoryForm'

extend('min_value', min_value)

export default {
  name: 'CreatePart',
  components: {
    CreateCategoryForm,
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      name: '',
      description: '',
      model: '',
      image: null,
      file: null,
      categoryId: null,
      partEntities: [],
      entities: [],
      categories: [],
      serverErrors: {
        model: null,
      },
      requesting: false,
      showNewCategoryDialog: false,
    }
  },

  created () {
    Promise.all([CategoriesRepository.index(), EntitiesRepository.list()])
      .then(([categories, entities]) => {
        this.categories = categories.data
        this.entities = entities.data
      })
      .catch(() => {

      })
  },
  methods: {
    handleCategoryCreated (category) {
      this.categories.push(category)
      this.categories = this.categories.sort((c1, c2) => {
        return c1.description > c2.description ? 1 : -1
      })
      this.categoryId = category.value

      this.showNewCategoryDialog = false
    },
    submit () {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.createPart()
        }
      })
    },
    createPart () {
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('model_identifier', this.model)
      formData.append('description', this.description)
      formData.append('category_id', this.categoryId)
      if (this.image) {
        formData.append('image', this.image)
      }

      this.requesting = true
      PartsRepository.store(formData)
        .then(({ data }) => {
          const part = data
          const requests = []
          this.partEntities.forEach((entityPart, i) => {
            const body = {
              part_id: part.id,
              stock_in: entityPart.quantity,
            }
            requests.push(
              EntityPartsRepository.store(entityPart.id, body),
            )
          })
          return Promise.all(requests)
        })
        .then(() => {
          this.$store.commit('snackBar/setSnack', {
            color: 'primary',
            message: 'Parts Created',
          })
          this.$router.push('/parts')
        })
        // eslint-disable-next-line handle-callback-err
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const data = response.data.errors
            if (data.model_identifier) {
              this.serverErrors.model = data.model_identifier[0]
            }
          }
        })
    },
    onFileChange (file) {
      this.image = file
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          cartTitle: 'New Part',
          cardSubTitle: 'Part Description',
          description: {
            hint: 'Press enter now new line',
          },
          quantitiesTitle: 'Parts Quantity',
          newCategory: 'New Category',
          addEntity: 'Associate entity',
        },
      },
      it: {
        c: {
          cartTitle: 'Nuova Part',
          cardSubTitle: 'Parte descrizione',
          description: {
            hint: 'Premere invio ora nuova riga',
          },
          quantitiesTitle: 'Quantità di parti',
          newCategory: 'Categoria nuova',
          addEntity: 'Entità associata',

        },
      },

    },
  },
}
</script>

<style scoped>

</style>
